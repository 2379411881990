<template>
  <b-container>
    <page-header name="Perfis">
      <b-button
        variant="primary"
        @click="newProfile"
      >
        <FeatherIcon icon="PlusIcon" />
        &nbsp;<span class="text-nowrap">Adicionar</span>
      </b-button>
    </page-header>

    <profile-new
      :uuid="uuidPerfil"
      :is-active.sync="isActivated"
      @onProfileSave="onProfileSave()"
      @onCancel="onCancelEdit()"
    />

    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
    >
      <template
        #cell(action)="data"
        style="width: 20%"
      >
        <div class="px-4 text-center">
          <i2-icon-button
            icon="EditIcon"
            variant="outline-primary"
            class="mr-1"
            @click="setCurrentProfile(data.item)"
          />
          <i2-icon-button
            fa-icon="fa-trash"
            variant="outline-danger"
            class="mr-1"
            @click="deleteProfile(data.item)"
          />
        </div>
      </template>
    </Grid>
  </b-container>
</template>

<script>
import UserNew from '@/views/users/UserNew.vue'
import Grid from '@/components/i2/grid/Grid.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userType from '@/variables/userTypes'
import ProfileNew from '@/views/profiles/ProfileNew.vue'

export default {
  components: {
    ProfileNew,
    Grid,
    UserNew,
  },
  data() {
    return {
      uuidPerfil: null,
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-80',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-20',
        },
      ],
      items: [],
      isActivated: false,
    }
  },
  watch: {
    isActivated(val) {
      if (!val) {
        this.uuidPerfil = null
      }
    },
  },
  mounted() {
    this.getProfiles()
  },
  methods: {
    async getProfiles() {
      console.log('entrou aqui')
      const response = await this.$http.get('profiles')
      this.items = response
    },
    setCurrentProfile(profile) {
      this.uuidPerfil = profile.uuid
      this.isActivated = true
    },
    newProfile() {
      this.uuidPerfil = null
      this.isActivated = true
    },
    onProfileSave() {
      this.uuidPerfil = null
      this.isActivated = false
      this.getProfiles()
    },
    deleteProfile(profile) {
      this.$bvModal.msgBoxConfirm('Por favor, confirme a exclusão do perfil.', {
        title: 'Confirm exclusão?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async value => {
          if (value) {
            const response = await this.$http.delete(`profiles/${profile.uuid}`)
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `O perfil ${profile.name} foi removido com sucesso.`,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.getProfiles()
            }
          }
          return true
        })
    },
    onCancelEdit() {
      this.isActivated = false
      this.uuidPerfil = null
    },
  },
}
</script>
<style>

</style>
