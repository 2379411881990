<template>
  <b-modal
    id="form-sidebar"
    ref="sideBar"
    size="lg"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    title="Perfis"
    right
    shadow
    ok-title="Salvar"
    cancel-title="Cancelar"
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    @ok="onSubmit"
    @cancel="onCancel"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #header="{hide}">
      <div class="d-flex justify-content-between align-items-center w-100">
        <feather-icon
          class="ml-1 cursor-pointer float-right"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
    </template>
    <template #footer="{hide}">
      <div class="p-1">
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >

            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-2"
              @click="onSubmit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              type="button"
              @click="onCancel"
            >

              <font-awesome-icon
                prefix="far"
                :icon="['far', 'times-circle']"
                class="mr-50"
              />
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <template #default="{ hide }">
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.name"
                rules="required"
                label="Nome"
                placeholder="informe o nome do perfil"
                name="name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-multiple-select
                v-model="model.menus"
                rules="required"
                label="Menus"
                placeholder="selecione os menus que o perfil terá acesso"
                name="menus"
                :options="menusAvailable"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import I2FormMultipleSelect from '@/components/i2/forms/elements/I2FormMultipleSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    I2FormInput,
    I2FormMultipleSelect,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    uuid: {
      type: String,
      required: false,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      menusAvailable: [],
      model: {
        name: '',
        uuid: null,
        menus: [],
      },
    }
  },
  watch: {
    uuid(value) {
      this.model.uuid = value
      this.getDataProfile()
    },
  },
  async mounted() {
    await this.getAvailableMenus()
  },
  methods: {
    async getDataProfile() {
      if (!this.model.uuid) {
        return
      }
      const response = await this.$http.get(`profiles/${this.model.uuid}`)
      this.model = {
        uuid: response.uuid,
        name: response.name,
        menus: response.menus.map(menu => ({
          text: menu.name,
          uuid: menu.uuid,
        })),
      }
    },
    onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.refFormObserver.validate()
        .then(async success => {
          if (success) {
            let response = {}
            const data = { ...this.model }
            data.menusUuid = this.model.menus.map(menu => menu.uuid)
            if (this.model.uuid) {
              response = await this.$http.put(`profiles/${this.model.uuid}`, data)
            } else {
              response = await this.$http.post('profiles', data)
            }
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
              return
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Perfil salvo com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.resetModel()
            this.$emit('onProfileSave', response)
            this.$refs.refFormObserver.reset()
          }
        })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.resetModel()
      this.$refs.refFormObserver.reset()
    },
    async getAvailableMenus() {
      const allMenus = await this.$http.get('menus')
      this.menusAvailable = allMenus.map(menu => {
        if (menu.active) {
          return {
            uuid: menu.uuid,
            text: menu.name,
          }
        }
      })
    },
    resetModel() {
      this.model = {
        uuid: null,
        name: null,
        menus: [],
      }
    },
  },
}
</script>
